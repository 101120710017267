import React from "react"
import Hero from "../../components/hero"
import SiteMetaData from "../../components/sitemeta"
import teamStyles from "../../components/teams.module.scss"
import TeamData from "../../components/team-data"
import Layout from "../../components/layout-teams"

const MensTeam = ({data}) => (
  <Layout data={data}>
    <div style={({"backgroundColor":"black"})}>
      <SiteMetaData 
        title="RF Men's Team | RF Dance"
        description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
        pathname="teams/men"/>
      <Hero 
        titleRed="RF"
        titleWhite="Men" />

      <div className={`${teamStyles.auditions} content-block`}>
        <div className={`${teamStyles.container} container`}>
          <TeamData
              name="RF Mens Team Auditions"
              subheading="All levels"
              link="https://www.eventbrite.com/e/92295730013"
              date="March 30, 2020"
              >
              <p>This is a welcoming, all-levels Men’s team with a focus on individual footwork patterns.</p>
            </TeamData>

        </div>
      </div>
    </div>
  </Layout>
  
)

export default MensTeam